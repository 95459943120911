import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';

function Toppage(){
    // console.log("Toppage component is rendering");
    // console.log("API URL: ", process.env.REACT_APP_API_URL);
    return(
    <Container fluid id="page-top" className="container-fluid">
        {/* Image section */}
        <div  className="my-3 d-flex justify-content-center mx-auto" style={{ fontFamily: 'Jacques Francois, serif' }}>
        <img 
            src={`${process.env.REACT_APP_API_URL}/images/title.png`}
            alt="logo de BenoitReiss"
            style={{ height: '50px' }}
        />
        </div>

         {/* Carousel Section */}
        <div className="d-flex flex-column bg-body-secondary py-3">
        {/* <h4 className="text-center" style={{ fontFamily: 'Jacques Francois, serif' }}>- Couvertures du livres -</h4> */}
        <Carousel className="carousel slide col-8 mx-auto" id="carouselExampleAutoplaying" indicators={false}>
        <Carousel.Item>
          <img
            src={`${process.env.REACT_APP_API_URL}/images/1.png`}
            className="d-block w-100"
            alt="First Slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={`${process.env.REACT_APP_API_URL}/images/2.png`}
            className="d-block w-100"
            alt="Second Slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={`${process.env.REACT_APP_API_URL}/images/3.png`}
            className="d-block w-100"
            alt="Third Slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={`${process.env.REACT_APP_API_URL}/images/4.png`}
            className="d-block w-100"
            alt="Fourth Slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={`${process.env.REACT_APP_API_URL}/images/5.png`}
            className="d-block w-100"
            alt="Fourth Slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={`${process.env.REACT_APP_API_URL}/images/6.png`}
            className="d-block w-100"
            alt="Fourth Slide"
          />
        </Carousel.Item>

        {/* Carousel Controls */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </Carousel>
        {/* <Carousel className="carousel slide col-8 mt-2 mx-auto" id="carouselExampleAutoplaying">
            <Carousel.Item>
                <img src={`${process.env.REACT_APP_API_URL}/images/gueule_demi.jpg`} className="d-block w-100" alt="First Slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={`${process.env.REACT_APP_API_URL}/images/bonheur.png`} className="d-block w-100" alt="Second Slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={`${process.env.REACT_APP_API_URL}/images/l_anglais_volant.jpg`} className="d-block w-100" alt="Third Slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={`${process.env.REACT_APP_API_URL}/images/notes_decoupees_du_japon.jpg`} className="d-block w-100" alt="Fourth Slide" />
            </Carousel.Item>
        </Carousel> */}
        <p className="text-center mt-3 fs-5" style={{ fontFamily: 'Jacques Francois, serif' }}>
            <a href="#livres" className="icon-link">
            Voir tous les livres
            <i className="bi bi-chevron-right"></i>
            </a>
        </p>
        </div>


        {/* Feature Section */}
        <div className="col-8 mx-auto" style={{ fontFamily: 'Jacques Francois, serif' }}>
            <Row className="row-cols-lg-3 g-4">
            <Col>
                <div className="card">
                <img
                    src={`${process.env.REACT_APP_API_URL}/images/rencontreM.jpg`}
                    className="card-img-top w-50 mx-auto"
                    alt="Rencontres"
                />
                <div className="card-body">
                    <h5 className="card-title text-center">
                    Rencontres <i className="bi bi-people-fill"></i>
                    </h5>
                    <p className="card-text">
                    <span>
                        <a
                        href="https://www.facebook.com/mediathequespaysdemeaux/posts/pfbid0JKQXERk32s36RwctmezisBSu1AaTZGsJzTKjJSSZoe4FSsHTQh5sPbkBoXBdrXRAl"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Les médiathèques du Pays de Meaux
                        </a>
                    </span>
                    :<br /> vendredi 11 octobre à 18h
                    </p>
                </div>
                </div>
            </Col>
            <Col>
                <div className="card">
                <img
                    src={`${process.env.REACT_APP_API_URL}/images/gueule_demi.jpg`}
                    className="card-img-top w-50 mx-auto"
                    alt="Prix Littératures"
                />
                <div className="card-body">
                    <h5 className="card-title text-center">
                    Prix Littératures <i className="bi bi-award-fill"></i>
                    </h5>
                    <p className="card-text">
                    Prix des Lecteurs du Réseau des médiathèques du Pays de Meaux 2024
                    </p>
                </div>
                </div>
            </Col>    
            <Col>
                <div className="card">
                <img
                    src={`${process.env.REACT_APP_API_URL}/images/un_dedale_de_ciels.jpg`}
                    className="card-img-top w-50 mx-auto"
                    alt="Prix Littératures"
                />
                <div className="card-body">
                    <h5 className="card-title text-center">
                    Prix Littératures <i className="bi bi-award-fill"></i>
                    </h5>
                    <p className="card-text">
                    Prix Max Jacob mention spéciale ‘’Découverte’’ 2023
                    <br />
                    Prix international de Littérature française de l’Académie royale de Belgique 2023
                    </p>
                </div>
                </div>
            </Col>
            </Row>
        </div>
    </Container>
    );}

export default Toppage;