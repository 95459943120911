import React, { useState, useEffect } from 'react';
import {Nav, Navbar, Button, NavDropdown,Form, FormControl, NavbarToggle, NavbarCollapse, NavLink, NavItem, Input} from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom'; // Linkをインポート
import { Link as ScrollLink, scroller } from 'react-scroll';


function Header(){
  const [books, setBooks] = useState([]);  // 本のデータを保存するステート
  const [loading, setLoading] = useState(true);  // ローディング状態
  const [error, setError] = useState(null);  // エラーステート
  const [searchTerm, setSearchTerm] = useState('');  // 検索語
  const [filteredBooks, setFilteredBooks] = useState([]);  // フィルタされた本のリスト
  const navigate = useNavigate();
  
  // トップページに移動した後に特定セクションにスクロール
  const scrollToSectionAfterNavigate = (sectionId) => {
    navigate('/');  // トップページに移動
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -120, // 100px上にスクロール位置を調整
      });
    }, 100);  // ページレンダリングを待ってからスクロール
  };

  // APIから本のデータを取得
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        console.log(`${process.env.REACT_APP_API_URL}/book/`);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/book/`);
        if (!response.ok) {
          throw new Error('データの取得に失敗しました');
        }
        const data = await response.json();
        // 本をIDの若い順にソート
        const sortedBooks = data.sort((a, b) => a.id - b.id);
        setBooks(sortedBooks);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchBooks();
  }, []);  // 初回レンダリング時のみ実行

  // 検索機能: 検索語が入力されたらフィルタリング
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    // タイトルに検索語が含まれる本をフィルタリング
    const filtered = books.filter(book => 
      book.title.toLowerCase().includes(term)
    );
    setFilteredBooks(filtered);
  };

  // ローディング中やエラーが発生した場合の処理
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

    return(
    <header className="d-flex align-items-center shadow sticky-top z-3 bg-white">
      <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <a href="/" className="badge text-bg-danger text-decoration-none fs-6" style={{ fontFamily: 'Jacques Francois', serif: 'serif' }}>
          BENOITREISS.COM
        </a>
        <Navbar expand="lg" className="fs-5" style={{ fontFamily: 'Jacques Francois', serif: 'serif' }}>
          <Navbar.Toggle aria-controls="navbarNavDropdown" />
          <Navbar.Collapse id="navbarNavDropdown">
          <Nav className="me-auto">
            <Nav.Link onClick={() => scrollToSectionAfterNavigate('page-top')}>Accueil</Nav.Link>
            <Nav.Link onClick={() => scrollToSectionAfterNavigate('bio')}>Biographie</Nav.Link>
             {/* ドロップダウンメニューを動的に生成 */}
            <NavDropdown title="Tous les livres" id="navbarScrollingDropdown">
            <NavDropdown.Item onClick={() => scrollToSectionAfterNavigate('livres')}>
              Tous les livres
            </NavDropdown.Item>
              {books.map((book) => (
                <NavDropdown.Item key={book.id} as={Link} to={`/book/${book.id}`}>
                  {book.title}
                </NavDropdown.Item>
              ))}
          </NavDropdown>
          <Nav.Link onClick={() => scrollToSectionAfterNavigate('renraku')}>Contacter</Nav.Link>  
        </Nav>
{/* 検索フォーム */}
<Form className="d-flex">
              <FormControl
                type="search"
                placeholder="Entrez le titre du livre"
                className="me-2"
                aria-label="Search"
                value={searchTerm}
                onChange={handleSearch}  // 入力時に検索
              />
              <Button variant="outline-secondary">Search</Button>
            </Form>

            {/* 検索結果を表示するドロップダウン */}
            {searchTerm && (
              <div className="search-results-dropdown">
                <ul>
                  {filteredBooks.map((book) => (
                    <li key={book.id}>
                      <Link to={`/book/${book.id}`}>{book.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
      </Navbar.Collapse>
    </Navbar>
      </div>
    </header>
    );
}

export default Header;