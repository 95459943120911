import React from 'react';
import API_URL from '../config'; 
import {Nav, Navbar, Button, Container, NavDropdown,Form, FormControl, NavbarToggle, NavbarCollapse, NavLink, NavItem, Input} from "react-bootstrap";

function Footer(){
    return(
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <div className="col-md-4 d-flex align-items-center">
                <a href="/" className="mb-3 me-2 mb-md-0">
                    <img width="200" src={`${process.env.REACT_APP_API_URL}/images/title.png`} alt="Benoit REISS Site logo"/>
                </a>
                <span className="mb-3 mb-md-0 text-body-secondary mx-auto">© 2024 Benoît REISS</span>
                <a href="https://www.instagram.com/benoit_reiss/" className="text-danger"><i className="bi bi-instagram fs-2"></i></a>
            </div>
      </footer>
    );
}

export default Footer;