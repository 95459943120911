import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap'; // React-Bootstrapをインポート
import API_URL from '../config'; 

function ContactForm() {
  // フォームの状態管理
  const [formData, setFormData] = useState({
    name: '',
    firstname: '',
    email: '',
    objet: '',
    inquiry: '',
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const [captchaToken, setCaptchaToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // フォームの入力を管理
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // reCAPTCHAのトークンを取得する関数
  const getCaptchaToken = async () => {
    
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute('6Ldq5A8qAAAAAMHcSqtdVjCGG3EJSDFmvuh-O20D', { action: 'submit' })
          .then(token => {
            setCaptchaToken(token); // ここでトークンをセット
            console.log('Captcha Token:', captchaToken);
            resolve(token);
          })
          .catch(err => reject(err));
      });
    });
  };

  // フォームの送信処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages([]);
    setIsSubmitting(true);
    console.log(formData);  // ここでフォームデータを確認

    // 入力のバリデーション
    const errors = [];
    if (!formData.name) errors.push("* Nom est obligatoire");
    if (!formData.firstname) errors.push("* Prénom est obligatoire");
    if (formData.name === formData.firstname) errors.push("* Nom et Prénom ne peuvent pas être identiques");
    if (!formData.email.match(/.+@.+\..+/)) errors.push("* Veuillez saisir l'email correct");
    if (!formData.objet) errors.push("* Titre du message est obligatoire"); // 追加
    if (!formData.inquiry) errors.push("* Message est obligatoire"); // 追加

    // エラーがあれば表示し、処理を中断
    if (errors.length > 0) {
      setErrorMessages(errors);
      setIsSubmitting(false);
      return;
    }

    
    // フォームのデータをサーバーに送信
    try {
      const token = await getCaptchaToken(); // reCAPTCHAトークンを取得
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, 'g-recaptcha-response': token }),
      });

      const result = await response.json();
      console.log(result);  // レスポンスの確認

      if (response.ok) {
            setSuccessMessage(result.message);  // 成功メッセージをセット
            setErrorMessages([]);  // エラーメッセージをクリア
          } else {
            setErrorMessages([result.error || 'Unknown error occurred']);  // エラーメッセージをセット
            setSuccessMessage('');  // 成功メッセージをクリア
          }
        } catch (error) {
          setErrorMessages([error.message || 'Form submission failed. Please try again.']);
          setSuccessMessage('');  // 成功メッセージをクリア
        } finally {
          setIsSubmitting(false);
        }
      };

  return (
    <Container id="renraku" className="bg-body-secondary p-3" style={{ fontFamily: 'Times New Roman, Times, serif' }}>
      <Row className="justify-content-md-center">
        <h2 className="text-secondary fs-2 mt-2 text-center">Formulaire de contact</h2>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-md-center">
          <Col md={4}>
            <Form.Group className="mb-3" controlId="nomInput">
              <Form.Label>Nom*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Votre nom"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="prenomInput">
              <Form.Label>Prénom*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Votre prénom"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="emailInput">
              <Form.Label>Email address*</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="titleInput">
              <Form.Label>Titre du message</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titre"
                name="objet"
                value={formData.objet}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3" controlId="messageInput">
              <Form.Label>Votre message*</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                placeholder="Contenu"
                name="inquiry"
                value={formData.inquiry}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col md={4} className="d-flex justify-content-between">
            <Button variant="light" type="reset" disabled={isSubmitting}>Reset</Button>
            <Button variant="secondary" type="submit" disabled={isSubmitting}>Submit</Button>
          </Col>
        </Row>

        {/* エラーメッセージの表示 */}
        {errorMessages.length > 0 && (
          <Row className="justify-content-md-center mt-3">
            <Col md={8}>
              <div className="text-danger fs-5">
                {errorMessages.map((message, index) => (
                  <p key={index}>{message}</p>
                ))}
              </div>
            </Col>
          </Row>
        )}

        {/* 送信成功メッセージの表示 */}
        {successMessage && (
          <Row className="justify-content-md-center mt-3">
            <Col md={8}>
              <div className="text-success fs-5">
                <p>{successMessage}</p>
              </div>
            </Col>
          </Row>
        )}

      </Form>
    </Container>
  );
}

export default ContactForm;

