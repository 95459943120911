import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';  // React Routerを使ってURLのパラメータを取得
import { Container, Row, Col, Button, Pagination } from 'react-bootstrap';


// 日付をdd/mm/yyyy形式でフォーマットする関数
function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);
}

// テキスト内の\nを<br>に変換する関数
function formatTextWithLineBreaks(text) {
  if (!text) return null;

  // リテラルな `\n\n`（2段改行）を `<br /><br />` に変換し、`\n`（1段改行）を `<br />` に変換
  const formattedText = text
    .replace(/\\n\\n/g, '\n\n')  // まず `\\n\\n` を改行コードに変換
    .replace(/\\n/g, '\n');      // `\\n` を改行コードに変換

  // 改行コードを `<br>` タグに変換して表示
  return formattedText.split('\n\n').map((paragraph, paragraphIndex) => (
    <React.Fragment key={paragraphIndex}>
      {paragraph.split('\n').map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {line}
          <br />
        </React.Fragment>
      ))}
      <br /> {/* 2段改行に対応 */}
    </React.Fragment>
  ));
}
// function formatTextWithLineBreaks(text) {
//   if (!text) return null;

//   // 改行コード (\n) を <br /> に置き換える
//   const formattedText = text.replace(/\n\n/g, '<br><br>').replace(/\n/g, '<br>');

//   // HTMLを直接挿入するために dangerouslySetInnerHTML を使用
//   return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
// }


function BookDetail() {
  const { id } = useParams();  // URLから本のIDを取得
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookIds, setBookIds] = useState([]); // 全ての本のIDを保存
  const navigate = useNavigate();  // ページ遷移用

  // ページがロードされたときにデータを取得
  useEffect(() => {
    const fetchBookDetail = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/book/${id}`);
        if (!response.ok) {
          throw new Error('Book not found');
        }
        const data = await response.json();
        // console.log(data.description);  // ここでデータベースからの値を確認
        setBook(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchBookIds = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/book`);
        if (!response.ok) {
          throw new Error('Failed to fetch book IDs');
        }
        const data = await response.json();
        const ids = data.map(book => book.id);
        setBookIds(ids);
      } catch (error) {
        setError(error.message);
      }
    };


    fetchBookDetail();
    fetchBookIds(); // 総本数を取得
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const currentIndex = bookIds.indexOf(Number(id));
  const hasPrevious = currentIndex > 0;
  const hasNext = currentIndex < bookIds.length - 1;

  const handlePrevious = () => {
    if (hasPrevious) {
      const previousId = bookIds[currentIndex - 1];
      navigate(`/book/${previousId}`);
    }
  };

  const handleNext = () => {
    if (hasNext) {
      const nextId = bookIds[currentIndex + 1];
      navigate(`/book/${nextId}`);
    }
  };

  return (
    <Container fluid className="pt-5 mt-1">
      {/* ページネーション */}
      <Row className="d-flex justify-content-center mt-0">
        <Pagination className="justify-content-center text-secondary text-decoration-none">
          <Pagination.Prev onClick={handlePrevious} disabled={!hasPrevious}>
            &laquo; Previous
          </Pagination.Prev>
          {/* <Pagination.Item active>{id}</Pagination.Item> */}
          <Pagination.Next onClick={handleNext} disabled={!hasNext}>
            Next &raquo;
          </Pagination.Next>
        </Pagination>
      </Row>

      <Row className="d-md-flex">
        <Col md={2}></Col> {/* 空のカラム */}

        {/* 本のカバー画像と購入リンク */}
        <Col md={3} className="text-center">
          <img 
            src={`${process.env.REACT_APP_API_URL}/${book.cover}`} 
            alt={`${book.title} cover`} 
            className="img-fluid shadow-lg mb-3"
          />
          <a href={book.buylink} target="_blank" rel="noopener noreferrer">
            <Button variant="dark" style={{ fontFamily: 'Linden Hill, serif' }}>
              Acheter ce livre
            </Button>
          </a>
        </Col>

        <Col md={1}></Col> {/* 空のカラム */}

        {/* 本の詳細情報 */}
        <Col md={5} style={{ fontFamily: 'Jacques Francois, serif' }}>
        <h4 className="mb-0">{book.title}</h4>
        
        <a 
            href={book.publisherLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-decoration-none"
        >
            <p className="fs-5 text-body">{book.publisher}</p>
        </a>

        {/* イラストレーターの情報がある場合だけ表示 */}
        {book.illustrateur && (
            <p className="fs-5 text-body">Illustrateur: {book.illustrateur}</p>
        )}

        {/* 出版日をdd/mm/yyyy形式で表示 */}
        <p className="text-muted">Date de publication : {formatDate(book.publishedYear)}</p>

        {/* Quoteを表示する */}
        {book.quote && (
            <p className="fs-6 text-secondary">{formatTextWithLineBreaks(book.quote)}</p>
        )}

        {/* Descriptionがある場合だけ表示 */}
        {book.description ? (
            <p className="fs-6 text-secondary">{formatTextWithLineBreaks(book.description)}</p>
        ) : book.quote ? (
            <p className="fs-6 text-secondary">{formatTextWithLineBreaks(book.quote)}</p>  // Descriptionがない場合、Quoteをもう一度表示
        ) : null}

        </Col>

        <Col md={1}></Col> {/* 空のカラム */}
      </Row>
      

    </Container>
  );
}

export default BookDetail;
