import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main';
import BookDetail from './components/BookDetail';

function App() {
  return (
    <Router>
      <div className="App">
        {/* 全ページ共通のヘッダー */}
        <Header />

        {/* ルートごとに表示するコンテンツ */}
        <Routes>
          {/* メインページ（トップページ）のルート */}
          <Route path="/" element={<Main />} />
          
          {/* 本の詳細ページのルート */}
          <Route path="/book/:id" element={<BookDetail />} />
        </Routes>

        {/* 全ページ共通のフッター */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
