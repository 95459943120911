import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import API_URL from '../config'; 

function Biographie() {
  return (
    <Container fluid id="bio" className="d-md-block" style={{ maxWidth: '100%' }}>
      <div className="d-flex justify-content-center">
        <h2 className="m-4 p-2" style={{ fontFamily: 'Jacques Francois, serif' }}>
          - Biographie -
        </h2>
      </div>

      <Row className="mx-auto bg-body-secondary">
        {/* 画像セクション */}
        <Col md={4} className="mx-auto my-3">
          <img
            src={`${process.env.REACT_APP_API_URL}/images/photo_cover.jpg`}
            alt="Portrait photo"
            className="img-fluid"
          />
        </Col>

        {/* テキストセクション */}
        <Col md={4} className="mx-auto m-2 p-2 d-md-flex" style={{ alignItems: 'center' }}>
          <p className="mx-5 fs-4 text-body-secondary" style={{ fontFamily: 'Linden Hill, serif' }}>
            Benoît Reiss est né en 1976. Il a fait des études de lettres à Lyon puis à Paris. 
            Il a travaillé dans l’édition scolaire avant de déménager en 2007 au Japon, dans la région de Tokyo, où il a enseigné le français.
            <br /><br />
            Depuis 2017, il est codirecteur de <a 
            className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" 
            href="https://www.cheyne-editeur.com/">
              Cheyne éditeur
            </a>, avec Elsa Pallot.
            <br /><br />
            Auteur, il a notamment publié en 2019 le roman Le Petit Veilleur chez Buchet Chastel, de la poésie à Cheyne et des récits dont O’Yu, un éloge de l’eau chaude aux éditions Esperluète en 2021.
            Benoît Reiss est représenté par <a 
            className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" 
            href="https://trames.xyz/">
              l'agence Trames
            </a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Biographie;
