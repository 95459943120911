import React from 'react';
import Toppage from './Toppage';
import Biographie from './Biographie';
import Livres from './Livres';
import ContactForm from './ContactForm';

function Main() {
  return (
    <main>
      <Toppage />
      <Biographie />
      <Livres />
      <ContactForm />
    </main>
  );
}

export default Main;