import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 

function Livres() {
  const [books, setBooks] = useState([]);  // 本のリストを保持するステート
  const [loading, setLoading] = useState(true);  // ローディング状態
  const [error, setError] = useState(null);  // エラーステート

  // APIから本のデータを取得する
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/book/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',  // リダイレクトを許可
        });
        if (!response.ok) {
          throw new Error('データの取得に失敗しました');
        }
        const data = await response.json();
        setBooks(data);  // 取得したデータをステートに保存
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);  // ローディング終了
      }
    };

    fetchBooks();
  }, []); // 初回レンダリング時のみ実行

  // ローディング中やエラーが発生した場合の処理
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // カードグループをレンダリングする
  const renderCardGroups = () => {
    const groups = [];
    for (let i = 0; i < books.length; i += 4) {
      const group = books.slice(i, i + 4);
      groups.push(
        <Row key={i} className="card-group col-10 justify-content-center mx-auto mt-3">
          {group.map(book => (
            <Col key={book.id} className="m-2" style={{ width: '16rem' }}>
              <Card>
                <Card.Img variant="top" src={`${process.env.REACT_APP_API_URL}/${book.cover}`} alt={book.title} />
                <Card.Body className="text-end" style={{ fontFamily: 'Times New Roman, Times, serif' }}>
                  {/* リンクを動的に生成。bookエンドポイントに対応 */}
                  <Link to={`/book/${book.id}`}>
                    <Button variant="danger">En savoir plus...</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      );
    }
    return groups;
  };

  return (
    <Container fluid id="livres">
      <div className="d-flex justify-content-center">
        <h2 className="m-4 p-2 text-center" style={{ fontFamily: 'Jacques Francois, serif' }}>- Mes livres -</h2>
      </div>
      <div className="d-flex flex-column">
        {renderCardGroups()}
      </div>
    </Container>
  );
}

export default Livres;
